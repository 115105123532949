.navbar {
    padding: 6px 30px !important;
}
.navbar .navbar-details {
    display: flex;
    align-items: center;
}
.navbar .navbar-details .nav-vendor-name-lg {
    text-align: left;
}
.navbar .navbar-details .nav-vendor-name-lg p {
    font-size: 18px;
    font-weight: bold;
}
.type-dark.show{
    max-width: 400px;
}
.logo-sn .large-logo img {
    width: 86px;
}
.logo-sn .small-logo img {
    width: 45px !important;
}
.transition-1s{ 
    transition: 0.2s;
}
.fa.fa-question-circle{
    font-size: medium !important;
}
.navbar .navbar-details .ms-aside-toggler, .ms-nav-toggler {
    padding-right: 28px;
}
.body-content {
    background-color: #fff;
}
.sidebar .side-nav {
    background-color: #fff3e8 !important;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.sidebar .side-nav .logo-sn .large-logo {
    padding: 14px 0 !important;
}
.sidebar .side-nav .logo-sn .small-logo {
    padding: 12px 0 !important;
}
.sidebar .side-nav .logo-sn, .side-nav .menu-item ul {
    background-color: #fff3e8 !important;
}
.side-nav .menu-item a {
    padding: 15px 0 15px 15px;
    font-size: 15.5px;
}
label {
    font-weight: 500;
}
/* .badge.badge-outline-light {
    border-color: #878793;
    color: #878793;
} */
.ms-list .ms-list-item{
    padding-top: 5px;
}
.form-control:disabled, .form-control[readonly]{
    background-color: #efefef;
}
.breadcrumb {
    background-color: #fff;
    margin-bottom: 0 !important;
}
.page-nav-info .page-title {
    font-size: 22px;
    font-weight: bold;
}
.page-nav-info .material-icons {
    vertical-align: text-top;
    padding-top: 3px;
}

.nav-tabs {
    margin-top: 5px;
}
.nav-tabs .tab-separator  {
    height: 16px;
    border-right: 0.5px solid #e0e0e0;
    margin-top: 14px;
}
.nav-tabs.tabs-bordered{
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.nav-tabs li {
     flex: none;
}
.nav-tabs.tabs-bordered li a {
    font-size: 16px;
    padding: 10px 20px !important;
    border-bottom: none;
}
.nav-tabs.tabs-bordered li a.active {
    font-weight: 600;
    background: transparent !important;
    border-bottom: 1px solid #ff8306;
}
.table-striped tbody tr:nth-of-type(odd){
    background-color: #f9f9f9;
}
.dataTables_wrapper table{
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}
.dataTables_wrapper tbody select{
   height: auto;
}
.dataTables_wrapper tbody td{
    vertical-align: middle;
}
.dataTables_wrapper tr th,
.category-list-table tr th {
    font-size: 16px;
}
.dataTables_wrapper .dataTables_filter input {
    border-radius: 5px;
    width: 300px !important;
}
.status-dot {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
    margin-right: .75rem;
}
.side-nav .menu-item a.active::before {
    display: none;
}
.side-nav li.menu-item a.active {
    background-color: #fff;
    width: 96%;
    border-radius: 30px 0px 0px 30px;
    margin-left: auto;
    color: #000;
}
.side-nav li.menu-item a.active span {
    color: #000;
    font-weight: 500;
}
.side-nav li.menu-item a {
    width: 96%;
    margin-left: auto;
    color: #636363;
}
.side-nav li.menu-item a span {
     color: #636363;
}


/* ms-panel - start */
.ms-panel {
    box-shadow: none;
    -webkit-box-shoadow: none;
    border: 1px solid #dee2e6;
}
.ms-panel-header h6 {
    text-transform: none;
}
/* ms-panel end */

/* Order details page - start */
.custom-panel .ms-panel-body {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.custom-panel .ms-panel-header:nth-child(1) {
    border-bottom: none;
    padding: 1.5rem 1.5rem 0rem 1.5rem;
}
.custom-table .ms-panel-header { 
    padding: 1rem !important;
}
.tbl-order-details thead th h6{
    margin-bottom: 0.2rem;
}
.tbl-order-details tbody td{
    vertical-align: middle;
}
.tbl-order-details tfoot td {
    padding: 0.35rem 0.75rem;
    border: none;
}
.tbl-order-details tfoot tr:nth-child(1) td{
    padding: 1rem 0.75rem 0.35rem 0.75rem;
}
.tbl-order-details tfoot tr:nth-last-child(2) td{
    padding: 0.35rem 0.75rem 1rem 0.75rem;
}
.tbl-order-details .thead-light th{
    color: #000;
}
.tbl-order-details tbody td:nth-child(1) {
    width: 15%;
}
.tbl-order-details tbody td:nth-child(2) {
    width: 40%;
}
.tbl-order-details tbody td:nth-child(3) {
    width: 20%;
}
.tbl-order-details tbody td:nth-child(4) {
    width: 15%;
}
/* Order details page - end */

/* Product list page - start */
.div-product-list table tbody td {
    padding: 0.50rem 0.75rem;
}
.div-product-list table tbody td:last-child {
    width: 10%;
}
.category-list-table table tbody td:last-child {
    width: 150px;
}
.category-list-table tr th {
    border-top: 1px solid #dee2e6 !important;
}
.category-list-table tr,
.tbl-schedule tr {
    cursor: grab;
}
/* Product list page - end */

/* Store management page - start */
.btn-add-email {
    border-radius: 100%;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 10px;
    margin: auto;
    margin-left: 5px;
    padding: inherit;
    border-width: 1px;
}
.btn-add-email .fas{
    font-size: 11px;
    line-height: 18px;
}
.custom-file {
    display: flex;
    justify-content: space-between;
    width: 11%;
}
.custom-file-input {
    width: 100%;
    outline: none;
    
}
.upload-file-input {
    outline: none;
    margin: 0;
    opacity: 0;
    width: 135px;
}
.custom-file p {
    margin-left: 145px;
    width: 100%;
    height: calc(2.25rem + 2px);
    line-height: 36px;
    white-space: nowrap;
}
.custom-file-label { 
    width: 135px;
}
.custom-file-label::after {
    width: 100%;
    text-align: center;
}
.pointer {
    cursor: pointer;
}
.opacity-05 {
    opacity: 0.5;
}
.upload-btn {
    display: inline-block;
    width: 100%;
}
.upload-file-input:lang(en)~.custom-file-label::after {
    content: "Upload File";
}
.custom-file-label::after {
    background-image: linear-gradient(#e7e7e7, #bfbbbb);
    color: #000;
}
.upload-file-input:focus~.custom-file-label {
    border-color: #eaeaea!important;
    box-shadow: none;
}
.custom-file-label { 
    width: 135px;
}
.custom-file-label::after {
    width: 100%;
    text-align: center;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Upload Photo";
}
.custom-file-input:lang(en)~.custom-csv-file-label::after {
    content: "Upload File";
}
.custom-file-label::after {
    background-image: linear-gradient(#e7e7e7, #bfbbbb);
    color: #000;
}
.custom-file-input:focus~.custom-file-label {
    border-color: #eaeaea!important;
    box-shadow: none;
}
.store-management-page .custom-file .invalid-feedback,
.add-product-page .custom-file .invalid-feedback {
    height: 34px;
    bottom: -37px;
}
/* Store management page - end */


/* Campaign & promotion - start */
.btn-setup-campaign {
    float: right;
    position: absolute;
    right: 0;
    margin-right: 15px;
    top: 130px;
}
.div-campaign-promotion table tbody td:nth-last-child(1),
.div-campaign-promotion table tbody td:nth-last-child(2){
    width: 10%;
}
.div-campaign-promotion table tbody td {
    padding: 0.50rem 0.75rem;
}
.div-delivery-partner table tbody td:nth-last-child(1){
    width: 10%;
}
.div-delivery-partner table tbody td:nth-last-child(2){
    width: 15%;
}
/* Campaign & promotion - end */

/* Print management - start */
.div-print-station-list table tbody td:last-child{
    width: 10%;
}
.div-print-station-list table tbody td {
    padding: 0.50rem 0.75rem;
}
.print-fooditem-group .card {
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.select-food_list-item.d-flex.justify-content-between.preview-food-item {
    padding: 6px 0px;
}
.preview-food-item .badge-food-item{
    margin: 0px 6px 6px 0px;
}
.d-contents {
    display: contents;
}
.p-t-20 { 
    padding-top: 20px !important;
}
.m-tt-59 {
    margin-top: -59px !important;
}
.filter-label {
    padding-top: 8px;
    padding-right: 10px;
    font-weight: 600;
}
/* Print management - end */

@media(max-width:575px){
    .page-nav-info {
         margin-bottom: 0px !important;
    }
    .btn-setup-campaign{
        position: relative;
        right: 0;
        top: 0;
        margin-bottom: 10px;
        margin-left: auto;
        display: flex;
        float: none;
    }
}
@media(max-width: 768px){
    .custom-file p {
        width: auto;
        margin-top: 32px;
        white-space: nowrap;
        margin-left: 0px;
    }   
    .div-campaign-promotion table tbody td:nth-last-child(2) {
        width: 15%;
    }
    .btn_add_coupon,.btn_add_print_station, .btn_add_preview_station  {
        position: relative;
        right:0;
        top: 0;
        float: right;
        margin-bottom:10px;
        margin-top: 0px;
        margin-left: 10px;
    }
}
@media(max-width: 1024px) and (min-width: 991px){ /*Css specific for iPad-pro*/
    .btn_add_coupon {
        position: relative;
        right:0;
        top: 0;
        float: right;
        margin-bottom:10px;
        margin-top: 0px;
    }
}

.custom-auth-container .custom-auth-col-4{
    width: 40%;
}

.custom-auth-container .custom-auth-col-8{
    width: 60%;
}
.unsave-footer {
    background: #f7830863;
    height: 50px;
    margin-left: -30px;
    /* margin-bottom: -30px; */
    margin-top: 25px;
    margin-right: -30px;
    position: fixed;
    bottom: 0;
    width: calc(100% - 180px);
    padding-right: 5px;
}
.unsave-footer p {
    display: inline-block;
    padding-left: 15px;
    padding-top: 15px;
    font-size: 18px;
}
.product-first-section {
    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.product-modifier-section {
    padding: 20px;
    margin-bottom: 1em;
    border: 1px solid #dee2e6;
    border-top: none;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.m-t-35 {
    margin-top: 35px;
}
.modifire-section{
    display: flex;
}
.modifire-section .option-drag-drop-main {
    flex: inherit;
    max-width: 100%;
    margin-top: 25px;
}
.modifire-section .option-drag-drop-main a{
    padding: 0;
    display: block;
}

.modifire-section .option-drag-drop-main a i {
    font-size: 20px;
  }
.modifire-section .row{
    width: 100%;
    margin: 0;
}
.font-25 {
    font-size: 25px;
}
.color-red {
    color: red;
}
#menuTable tbody tr td{
    height: 35px;
}

.btn-primary.disabled {
    color: #fff;
    background-color: #ff8306;
    border-color: #ff8306;
}