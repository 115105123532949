html {
  scroll-behavior: smooth;
}
.ms-auth-form { 
  padding: 1rem !important;
}
.nav-vendor-name{
  background-color: #fff;
  text-align: center;
  padding-right: 20px;
}
.nav-vendor-name.ms-d-block-sm {
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0px 0px 5px 0px;
}

.nav-vendor-name p{
  font-size: 20px;
  font-weight: 500;
}
.nav-vendor-name.nav-vendor-name-lg p{
  line-height: 18PX;
  margin-bottom: 8PX
}
.tabs-bordered li a{
  padding: 10px !important;
}
.nav-tabs.tabs-bordered li a.active{
  background: #ebebeb !important;
}
.ms-has-quickbar .body-content{
    padding-right: 0px;
}
.badge-cancelled{
    color: #34334a;
}
.action{
    color: #9c9c9c !important; 
  }
  .action:hover{
    color: #bc2634; 
  }
  .badge-cancel {
    background-color: #34334a;
}
.dashboard_table td a{
  text-decoration: underline;
}
.dashboard_blog .price {
  font-weight: 700;
  color: #217c1f;
}
.dashboard_blog .title {
  color: #141313;
  font-size: 16px;
  font-weight: 500;
}
.dashboard_blog .order_list {
  color: #828181;
}
.dashboard_blog .total_price {
  color: #ff0000;
}
.order-status {
  width: 70px;
}
.first_content {
  padding-bottom: 10px;
}
.dashboard_blog .ms-card {
  margin-bottom: 0;
}
.input_password .input-group-addon {
  position: absolute;
  top: 9px;
  right: 10px;
}
.restaurant-select {
  max-width:400px;
  width:100%;
  margin-left: 17%;
}
.restaurant-select li{
  padding:0;
  width:100%;
}
.ms-password-bg {
  position: relative;
  height: 100%;
  background-image: url(../img/set_password_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.btn-dark{
  color: #fff;
    background-color: #212121;
    border-color: #212121;
}

/* Change status Modal */
#modal-change-status .modal-body{
  padding: 2rem 2rem !important;
}
/* Change status Modal - End */

/* Datatable - Start */
.dataTable select.delivery-select-box {
  width: 170px !important;
}
.dataTable select{
  width: 125px !important;
}
.dataTables_paginate a {
  position: relative;
  display: inline-block;
  padding: .5rem .75rem;
  line-height: 1.25;
  color: #34334a;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-right: 0.425rem;
  outline: none;
  cursor: pointer;
}div.dataTables_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}div.dataTables_wrapper div.dataTables_paginate {
  display: flex;
}.paginate_button.previous {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.dataTables_paginate .ellipsis{
  margin-right: 0.425rem;
}

.dataTables_paginate a:hover {
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.dataTables_paginate a.current {
  color: #fff;
  background-color: #ff8306;
  border-color: #ff8306;
  z-index: 0;
}

div.dataTables_paginate {
  padding-top: 0.85em;
}
div.dataTables_filter input {
  border-radius: 25px;
  padding: 8px 14px;
  font-size: 14px;
  border: 1px solid rgba(0,0,0,0.1);
  outline: none;
  width: 180px;
}

div.dataTables_length select {
  font-size: 14px;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 4px;
  outline: none;
}
/* Datatable-end */

/* Login page - start */
.invalid-feedback{
  font-size: 94% !important;
}
.page-form .invalid-feedback{
  display: block;
}

.page-form .invalid{
  border-color: #db3c36;
}

.ms-auth-bg{
  position: relative;
  height: 100%;
  /* background-image: url('../img/login_bg.png'); */
  background-image: url('../img/login_bg1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
/* Login page - end */

/* Order details - start */
.tbl-order-details tbody td{
  border-top: none;
  border-bottom: 1px solid #dee2e6;
}

.tbl-order-details tfoot td{
  padding: 0.15rem 0.75rem 0.15rem 0.15rem;
  border-top: none;
}

/* Order details - end */

/* Store Management - Start */
.store-management-page .btn-save { 
  float: right;
}
/* .frm-set-vendor .invalid-feedback,
.frm-set-password .invalid-feedback{
  position: absolute !important;
  bottom: -22px !important;
} */

.store-management-page .input-group .invalid-feedback,
.add-product-page .input-group .invalid-feedback,
.input-group .invalid-feedback
{
  display: block;
  position: relative;
  text-align: left;
}
.input-group .css-2b097c-container { 
  width: 100%;
}
.store-management-page .custom-file .invalid-feedback,
.add-product-page .custom-file .invalid-feedback,
.input-group .invalid-feedback
{
  display: block;
  bottom: initial;
  text-align: left;
}
.store-management-page .invalid,
.add-product-page .invalid,
.invalid{
  /* color:  #db3c36; */
  border-color: #db3c36;
}
.store-management-page label.required,
.section-schedule label.required,
.add-product-page label.required,
.form-row label.required,
label.required {
  position: relative;
}
.store-management-page label.required:after,
.section-schedule label.required:after,
.add-product-page label.required:after,
.form-row label.required:after,
label.required:after{
  content: '*';
  color: red;
  position: absolute;
  right: -8px;
}

.react-date-picker__wrapper{
  border: none;
}
.section-offdays .form-add-offdays,
.section-schedule .form-add-schedule{
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.section-schedule #react-time-range{
  padding: 0px;
}
.section-schedule .div-new-schedule{
  background-color: #e8e8e8
}
.section-schedule .error{
  border: #db3c36;
  color: #db3c36
}
.card-body .card-body-checkbox {
  width: 30%;
  display: flex;
}
@media(max-width:1024px)
{
  .card-body .card-body-checkbox {
    width: 45%!important;
    margin-bottom: 5px!important;
}
}
@media(max-width:575px)
{
  .card-body .card-body-checkbox {
    margin: 0!important;
    width: 100%!important;
    margin-bottom: 5px!important;
}
}
.default-link {
  color: #ff8306 !important;
  cursor: pointer !important;
}
.default-link:hover {
  color: #bc2634 !important;
  cursor: pointer !important;
}

/* Menu management */
.btn_add_product {
  position: absolute;
  right: 15px;
  top: -85px;
  z-index: 99;
  color: #fff !important;
}
.btn_add_products {
  position: absolute;
  right: 170px;
  top: -85px;
  z-index: 99;
  color: #fff !important;
}
.btn_add_print_station {
  position: absolute;
  right: 381px;
  top: 56px;
  z-index: 99;
  color: #fff !important;
}
.btn_add_driver {
  position: absolute;
    right: 381px;
    top: 5px;
    z-index: 99;
    color: #fff !important;
    margin-top: 0;
}
.btn_add_preview_station {
  position: absolute;
  right: 558px;
  top: 56px;
  z-index: 99;
  color: #fff !important;
  padding: 12px 0;
}

.tbl_product_list .product_action{
  color: #9c9c9c; 
  margin-right: 20px;
}

/* Menu management - End */

/* Add Product */
.div_product_customization .ms-list{
  justify-content: space-between;
}

.div_product_customization .div_add_item{
  padding: 0.75rem 0;
}

.div_customization_items .div_choose_item {
  justify-content: space-between;
}

.div_customization_items .div_item_types .btn.active{
  color: #ff8306 !important;
  border-color: #ff8306 !important;
}
.d-flex.div_choose_item .ms-list .ms-list-item {
  padding: 0.50rem;
  margin-bottom: 0px;
}
.div_item_types .btn-outline-light {
  margin-right: 8px;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 15px !important;
}

.div_selected_items li.ms-list-item.media {
  padding: 0px 0px 10px;
}
.div_selected_items .product_action {
  font-size: 13px!important;
}
.div_selected_items i.fas.fa-pencil-alt.product_action {
  color: #000;
}

.div_selected_items li.ms-list-item.media {
  padding: 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div_selected_items .item_price_action{
  display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
}

.div_selected_items .item_price_action a{
  padding: 0px 5px;
}
.div_selected_items .item_price_action .div_action{
  text-align: right;
}

.div_selected_items .item_name{
  width:50%;
}

.submit_add_product .new {
  justify-content: flex-end !important;
}

.submit_add_product button:first-child {
  margin-right: 10px;
}

.product-img-wrapper
{
  padding: 25px;
}
.product-img-wrapper img
{
  max-height: 400px;
  width: 100%;
  object-fit: cover;
}

@media(max-width:1023px){
  .section-schedule table {
    width: 1110px;
  }
}

/* Add Product - End */

/* MARKETING MANAGEMENT - start */
.btn_add_coupon{
  position: absolute;
  right: 382px;
  top: 130px;
  z-index: 99;
  color: #fff !important;
}

.custom-input-group p {
  margin-bottom: 0px;
  padding: 7px 5px;
}

.custom-input-group input {
  width: 70px;
}
.custom-input-group {
  display: flex;
}


.react-responsive-modal-modal{
  width: 100%;
}
.fooditem-group div#accordion .card-header {
  display: flex;
}
.fooditem-group div#accordion  .card-header a {
  width: 100%;
}
.fooditem-group div#accordion .card-header a.collapsed.card-link {
  color: black !important;
}
.fooditem-group div#accordion .card-header a.collapsed.card-link:after {
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
  transform: rotate(0deg)!important;
}
.fooditem-group div#accordion .card-header a.card-link:after {
  transform: rotate(180deg);
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

.selected-food-items .ms-panel-header,
.selected-food-items .ms-panel-body{ 
  padding: 1rem;
}

.selected-food-items .ms-panel-header{
  background-color:#6c757d;
}
.selected-food-items .ms-panel-header h6{
  color: #fff;
}
.selected-food-items h6{
  font-size: 14px;
}
.selected-food-items .category-food .align-left, .food_c-name {
  width: 20%;
}
.selected-food-items .category-food .align-right, .food_c-content {
  width: 80%;
}
.ms-panel-body.category-food-list {
  overflow-y: auto;
}

.react-calendar__month-view__days__day--weekend{
  color: #000 !important;
}

.add-campaign-page .ms-panel-header::after,
.update-campaign-page .ms-panel-header::after {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 700;
  position: absolute;
  top: 18px;
  right: 1.25rem;
  transition: 0.3s;
  content: '\e313';
  font-size: 20px;
}

.add-campaign-page .ms-panel-header[aria-expanded="true"]::after,
.update-campaign-page .ms-panel-header[aria-expanded="true"]::after {
  transform: rotate(
180deg
);
}

.email-figures {
  padding: 20px 0px 0px;
}
.email-figures li .figure-value {
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
}
.email-figures li .figure-header {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.email-figures li {
  width: 33.33%;
  text-align: center;
  margin-bottom: 30px;
}
.email-figures ul {
  display: flex;
}

.tbl-analytics .ms-panel-header{ 
  padding: 1rem;
}
.tbl-analytics .ms-panel-body{
  overflow-y: auto;
  height: 150px;
  padding: 0px;
}
.tbl-analytics .tbl-analytics-body{
  padding: 10px 10px 0px 10px;
  border-bottom: 1px solid #dee2e6;
}
.tbl-analytics .tbl-analytics-body:hover{
  background-color: rgba(0,0,0,.075);
}
.tbl-analytics .ms-panel-header{
  background-color: #ff8306;
}
.tbl-analytics .ms-panel-header .tbl-analytics-header h6{
  color: #fff;
}
.tbl-analytics h6{
  font-size: 14px;
}
.tbl-analytics .tbl-analytics-body div:nth-child(1){
  font-weight: bolder;
}
.tbl-analytics .tbl-analytics-header div:nth-child(1),
.tbl-analytics .tbl-analytics-body div:nth-child(1){
  width: 10%;
  text-align: left;
}
.tbl-analytics .tbl-analytics-header div:nth-child(2),
.tbl-analytics .tbl-analytics-body div:nth-child(2){
  width: 70%;
  text-align: left;
}
.tbl-analytics .tbl-analytics-header div:nth-child(3),
  .tbl-analytics .tbl-analytics-body div:nth-child(3){
  width: 20%;
  text-align: left;
}
.food-items .card-body span.ml-1 {
  width: 30%;
  display: flex;
  margin-bottom: 5px;
}
.food-items .card-body {
  display: flex;
  flex-wrap: wrap;
}
/* MARKETING MANAGEMENT - end */

/* Media query - Start */
@media(max-width:767px){
  .btn_add_product, .btn_add_coupon, .btn_add_print_station{
    position: relative;
    right:0;
    top: 0;
    float: right;
    margin-bottom:10px;
  }
  .btn_add_products{
    position: relative;
    right: 30px;
    top: 0;
    float: right;
    margin-bottom:10px;
  }
}
@media(max-width:767px){
  .selected-food-items .category-food .align-left, .food_c-name {
    width: 50%;
  }
  .selected-food-items .category-food .align-right, .food_c-content {
    width: 50%;
  }
}
@media(max-width:991px){
  .email-figures li {
    width: 50%;
  }
  .row.detail-analytics .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
}
}

@media(max-width:540px){
  .tbl-analytics .ms-panel-header .d-flex:first-child {
    flex-direction: row;
}
.selected-food-items .ms-panel-header .d-flex:first-child {
  flex-direction: row;
}
.email-analytics{
  overflow-x: auto;
}
.email-analytics .tbl-analytics{
  width: 500px;
}
.email-figures ul {
  display: flex;
  flex-wrap: wrap;
}
}
@media(max-width:575px){
  .col-md-6.selected-food-items {
    overflow-x: scroll;
}
  .selected-food-items .ms-panel {
    width: 400px;
  }
}
@media(max-width: 768px){
  .nav-vendor-name.nav-vendor-name-lg{
    display: none;
  }
  .react-responsive-modal-modal{
    width: 94%;
  }
  div#editor-2 iframe {
    min-width: 100% !important;
  }
}
@media(max-width: 1024px) and (min-width: 991px){ /*Css specific for iPad-pro*/
  .col-lg-6.col-md-12.mb-2.tip-percentage, .div-restrict-days, .div-recipients, .ipad-pro-w-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .div-perncetage-off, .div-amount-off, .div-free-delivery,
   .div-specific-item, .div-schedule-campaign, .div-from-name, .ipad-pro-w-50{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .nav-vendor-name.nav-vendor-name-lg{
    display: none;
  }
  div#editor-2 iframe {
    min-width: 100% !important;
  }
}
/* Media query - End */

.div-premium-customer{
  margin: 0px;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 12px 8px 0 8px;
  margin-top: 2px;
  margin-bottom: 8px;
}
.add-product-page .invalid-feedback {
  width: 710px!important;
  left: 0;
}
.rdw-editor-wrapper {
  height: 300px;
}
.rdw-editor-main {
  background: #e1e1e1;
}
.mt-50 {
  margin-top: 50px;
}
.media-upload-btn {
  position: absolute;
  left: 150px;
  top: 0;
}
.display-contents {
  display: contents;
}
.media-upload-section .invalid-feedback {
  display: block;
  text-align: left;
  bottom: -40px;
}
.form-row.media-upload-section p {
  padding-top: 15px;
}
.media-upload-img img {
  display: inline-block;
  max-width: 220px;
  max-height: 220px;
  padding: 20px 5px;
}
.media-upload-img {
  position: relative;
  margin: 20px 5px 0;
}
.media-upload-icon-box {
  display: none;
  position: absolute;
  bottom: 27px;
  right: 14px;
  z-index: 1;
}
.media-upload-img:hover .media-upload-icon-box{
  display: block;
}
.media-upload-img:after{
  content:'';
  position: absolute;
  background-color:rgba(254,243,232,0.7);
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity: 0;
}
.media-upload-img:hover:after{
  opacity: 1;
}
.media-upload-img i.fa.fa-copy.text-muted.pointer {
  margin-right: 3px;
}

.card-body-grid {
  display: block;
  border-bottom: 1px solid #e1e1e1;
  padding: 10px;
}
.border-bottom-menumapping {
  border-bottom: 1px solid #e1e1e1;
}




/* 
 *  Food Variant Modal CSS
 */

.product-addition-options {
  margin-top: 15px;
}
.product-addition-options .radio {
  width: 30%;
}
.product-addition-options .radio label {
  font-size: 13px;
  margin-bottom: 0;
}
.radio {
  margin: 0.5rem;
}
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: 0em;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #ff914d;
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border: 1px solid #ff914d;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #ff914d;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
.product-addition-options{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  justify-content: flex-start;
}
.product-optional-items
{
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* justify-content: space-between; */
    justify-content: flex-start;
}
.product-addition-options h5,.product-optional-items h5 {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #000000;
}
  .product-optional-items .checkbox{
    /* width: auto; */
    width: 30%;
    margin-left: 0px;
}
.product-optional-items .checkbox label{
    font-size: 13px;
}
.product-optional-items h5 {
  margin-bottom: 15px;
}
.product-optional-items p {
  margin-bottom: 5px;
  margin-top: -15px;
  width: 100%;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
}
.product-optional-items {
  margin-top: 0px;
}
h5.childOptionTopping_name {
  display: flex;
}
.child-topping-box .childOptionTopping_name span.sub_topping_title_left {
  font-size: 13px;
  margin-top: 10px;
}
span.sub_topping_title_left {
  width: 40%;
}
h5.childOptionTopping_name.toppingName_main {
  margin-bottom: 15px;
}

.child-topping-box {
  width: 100%;
}
.display-contents {
  display: contents;
}
.radio_fancy input {
  opacity: 0;
}
.radio_fancy .radio_fancy_option {
  background: #ffffff;
  display: inline-block;
  /* margin: 0 10px; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.radio_fancy .radio_fancy_option input {
  width: 30px;
  height: 30px;
  vertical-align: top;
}

.radio_fancy .radio_fancy_option:last-child:before {
  content: '';
  width: 30px;
  height: 30px;
  background: #dadada;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #dadada;
  box-shadow: inset 0 0 0 3px #ffffff;
}
.radio_fancy .radio_fancy_option.check-topping-radio:last-child:before {
  background: #e49028;
  border: 2px solid #f7914d;
  box-shadow: inset 0 0 0 3px #ffffff;
}
.font-weight-300 {
  font-weight: 300;
}
.m-b-5 {
  margin-bottom: 5px;
}
.font-weight-700 {
  font-weight: 700;
}
.radio_fancy .radio_fancy_option:first-child:before {
  content: '';
  width: 30px;
  height: 30px;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #dadada;
  border-left: 15px solid #dadada;
}
.radio_fancy .radio_fancy_option.check-topping-radio:first-child:before {
  background: #ffffff;
  border: 2px solid #f7914d;
  border-left: 15px solid #f7914d;
}
.radio_fancy .radio_fancy_option:nth-child(2):before {
  content: '';
  width: 30px;
  height: 30px;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #dadada;
  border-right: 15px solid #dadada;
}
.radio_fancy .radio_fancy_option.check-topping-radio:nth-child(2):before
{
  background: #ffffff;
  border: 2px solid #f7914d;
  border-right: 15px solid #f7914d;
}
span.sub_topping_title span {
  font-size: 14px;
  color: #313438;
}

h5.childOptionTopping_name {
  display: flex;
}
.child-topping-box .childOptionTopping_name span.sub_topping_title_left {
  font-size: 13px;
  margin-top: 10px;
}
span.sub_topping_title_left {
  width: 40%;
}
.radio_fancy_wrapper,.sub_topping_title
{
  width:60%;
  display: flex;
}
.radio_fancy {
  width: 100%;
  display: flex;
}
span.sub_topping_title span,.radio_fancy_option {
  width: 33.33% !important;
  position: relative;
}
h5.childOptionTopping_name.toppingName_main {
  margin-bottom: 15px;
}
.check-lable-font-13 {
  font-size: 13px !important;
}
.width-100 {
  width: 100%;
}
.checkbox {
  display: block;
  margin-bottom: 15px;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label,.checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 15px;
}

.checkbox label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #e2e2e2;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 4px;
}
.checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 9px;
  height: 15px;
  border: solid #ff914d;
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}
.checkbox input:checked + label:before {
  border: 1px solid #ff914d !important;
}

@media (max-width: 420px){
  .product-optional-items .checkbox, .product-addition-options .radio {
      width: 100%;
  }
  .product-addition-options .radio {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 767px){  
  .product-optional-items .checkbox {
    width: 50%;
    margin-left: 0px;
  }
  span.sub_topping_title span{
      font-size: 12px!important;
  }
  .radio_fancy_wrapper, .sub_topping_title {
      width: 50%;
  }
  span.sub_topping_title_left {
      width: 50%;
  }
  .product-addition-options .radio {
      width: 50%;
      margin-left: 0;
      margin-right: 0;
  }
}
.cate-border {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}
.cate-collapse-icon.collapsed:after {
  transform: rotate(0deg);
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
  line-height: 0.5;
  margin-top: 5px;
}
.cate-collapse-icon:after {
  transform: rotate(180deg);
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
  line-height: 0.5;
  margin-top: 5px;
}
.cate-product-section {
  background: rgba(0,0,0,.03);
  padding: 15px 15px 0px 15px;
}
.cate-sub-product {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  display: block;
  padding: 10px;
}

.custom-link {
  cursor: pointer;
  transition: 0.3s;
  color: #ff8306;
  font-size: 14px;
}
.custom-link:hover, .custom-link:active, .custom-link:focus {
  color: #bc2634;
  text-decoration: none;
}
.cursor-move {
  cursor: move;
}
.p-l-25 {
  padding-left: 25px;
}
.p-t-2 {
  padding-top: 2px;
}
.width-auto {
  width: auto;
}
.modifier-display-name {
  font-size: 16px;
  font-weight: 600;
}
.card-body-modifier .row{
  align-items: center;
}
.option-drag-drop {
  flex: 0 0 0.333333%;
    max-width: 0.333333%;
}
.option-drag-drop a {
  padding-top: 8px;
    display: block;
}
.option-drag-drop p {
  padding-top: 8px;
    display: block;
}
.option-drag-drop a i {
  font-size: 20px;
}
.option-drag-drop p i {
  font-size: 20px;
}
.background-gray {
  background: #e1e1e1;
}
.m-t-8i {
  margin-top: 8px !important;
}